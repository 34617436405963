import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';


class BoxForm extends React.Component {
  constructor(props) {
    super(props);

    const { box = {} } = props;

    this.state = { box };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddPin = this.handleAddPin.bind(this);
    this.handlePinChange = this.handlePinChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { box } = nextProps;

    this.setState({ box });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { box } = this.state;
    const { handleSubmit } = this.props;

    handleSubmit(box);

    // this.setState({ box: {} });
  }

  handleChange(e) {
    const { box } = this.state;

    this.setState({ box: { ...box, [e.target.name]: e.target.value } });
  }

  handlePinChange(e, index) {
    console.log(e)
    const { box } = this.state;

    box.pins[index][e.target.name] = e.target.value
    this.setState({ box: { ...box, pins: box.pins } });
  }

  handleAddPin(e) {
    e.preventDefault();

    const { box } = this.state;
    box.pins.unshift({
      name: "",
      pinCode: ""
    })
    this.setState({ box: { ...box, pins: box.pins } });
  }

  render() {
    const { box: { name, ownerEmail, ownerName, ownerAddress, ownerPhoneNumber, ownerCity, ownerPostalCode } } = this.state;
    const { handleCancel, submitText = 'Save', classes } = this.props;

    return (
      <Grid container spacing={3}>

          <form onSubmit={this.handleSubmit}>
            <Grid item xs={12}>
              <TextField
                label="Box name"
                type="text"
                name="name"
                value={name ? name : ""}
                className={classes.textField}
                onChange={this.handleChange}
              />
            </Grid>

            <TextField
              label="Name"
              type="text"
              name="ownerName"
              value={ownerName ? ownerName : ""}
              className={classes.textField}
              onChange={this.handleChange}
            />
            <TextField
              label="Email"
              type="email"
              name="ownerEmail"
              value={ownerEmail ? ownerEmail : ""}
              className={classes.textField}
              onChange={this.handleChange}
            />
            <TextField
              label="Phone number"
              type="tel"
              name="ownerPhoneNumber"
              value={ownerPhoneNumber ? ownerPhoneNumber : ""}
              className={classes.textField}
              onChange={this.handleChange}
            />
            <TextField
              label="Address"
              type="text"
              name="ownerAddress"
              value={ownerAddress ? ownerAddress : ""}
              className={classes.textField}
              onChange={this.handleChange}
            />
            <TextField
              label="City"
              type="text"
              name="ownerCity"
              value={ownerCity ? ownerCity : ""}
              className={classes.textField}
              onChange={this.handleChange}
            />
            <TextField
              label="Zip Code"
              type="text"
              name="ownerPostalCode"
              value={ownerPostalCode ? ownerPostalCode : ""}
              className={classes.textField}
              onChange={this.handleChange}
            />
            {/* <Button onClick={this.handleAddPin}>+</Button>
            {pins.map((pin, index) => (
              <>
                <TextField
                  key={`box::${index}::name`}
                  label="Pin name"
                  type="text"
                  name="name"
                  value={pin.name ? pin.name : ""}
                  className={classes.textField}
                  onChange={(e) => this.handlePinChange(e, index)}
                />
                <TextField
                  key={`box::${index}::pin`}
                  label="Pin code"
                  type="text"
                  name="pinCode"
                  value={pin.pinCode ? pin.pinCode : ""}
                  className={classes.textField}
                  onChange={(e) => this.handlePinChange(e, index)}
                />
              </>
            ))} */}
            <Divider />
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" type="submit" color="secondary" className={classes.button}>
                  {submitText}
                </Button>
              </Grid>
            </Grid>
          </form>
      </Grid>
    );
  }
}
const formStyle = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  button: {
    margin: theme.spacing(1),
  },
  menu: {
    width: 200,
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  }
})

const useStyles = makeStyles(formStyle)

export const BoxFormFn = ({ box, handleCancel, submitText = 'Save' }) => {
  const [{box: { name, ownerEmail, ownerName, ownerAddress, ownerPhoneNumber, ownerCity, ownerPostalCode, pins }}, setState] = useState({box: box});
  const classes = useStyles()
  const handleSubmit = (e) => {
    e.preventDefault();
    const { box } = this.state;
    const { handleSubmit } = this.props;

    handleSubmit(box);

    // this.setState({ box: {} });
  }

  const handleChange = (e) => {
    setState( { ...box, [e.target.name]: e.target.value } );
  }

  const handlePinChange = (e, index) => {
    console.log(e)

    box.pins[index][e.target.name] = e.target.value
    setState( { ...box, pins: box.pins } );
  }

  const handleAddPin = (e) => {
    e.preventDefault();

    box.pins.unshift({
      name: "",
      pinCode: ""
    })
    setState( { ...box, pins: box.pins } );
  }
  return (
    <Grid container spacing={3}>

        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <TextField
              label="Box name"
              type="text"
              name="name"
              value={name ? name : ""}
              className={classes.textField}
              onChange={handleChange}
            />
          </Grid>

          <TextField
            label="Name"
            type="text"
            name="ownerName"
            value={ownerName ? ownerName : ""}
            className={classes.textField}
            onChange={handleChange}
          />
          <TextField
            label="Email"
            type="email"
            name="ownerEmail"
            value={ownerEmail ? ownerEmail : ""}
            className={classes.textField}
            onChange={handleChange}
          />
          <TextField
            label="Phone number"
            type="tel"
            name="ownerPhoneNumber"
            value={ownerPhoneNumber ? ownerPhoneNumber : ""}
            className={classes.textField}
            onChange={handleChange}
          />
          <TextField
            label="Address"
            type="text"
            name="ownerAddress"
            value={ownerAddress ? ownerAddress : ""}
            className={classes.textField}
            onChange={handleChange}
          />
          <TextField
            label="City"
            type="text"
            name="ownerCity"
            value={ownerCity ? ownerCity : ""}
            className={classes.textField}
            onChange={handleChange}
          />
          <TextField
            label="Zip Code"
            type="text"
            name="ownerPostalCode"
            value={ownerPostalCode ? ownerPostalCode : ""}
            className={classes.textField}
            onChange={handleChange}
          />
          <Button onClick={handleAddPin}>+</Button>
          {pins.map((pin, index) => (
            <div key={index}>
              <TextField
                key={`box::${index}::name`}
                label="Pin name"
                type="text"
                name="name"
                value={pin.name ? pin.name : ""}
                className={classes.textField}
                onChange={(e) => handlePinChange(e, index)}
              />
              <TextField
                key={`box::${index}::pin`}
                label="Pin code"
                type="text"
                name="pinCode"
                value={pin.pinCode ? pin.pinCode : ""}
                className={classes.textField}
                onChange={(e) => handlePinChange(e, index)}
              />
            </div>
          ))}
          <Divider />
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
              <Button variant="contained" type="submit" color="secondary" className={classes.button}>
                {submitText}
              </Button>
            </Grid>
          </Grid>
        </form>
    </Grid>
  )
}

export default withStyles(formStyle)(BoxForm);
