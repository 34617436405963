import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import startBoxImage from '../assets/homeb.jpg'
import { useAuth0 } from "../utils/react-auth0-wrapper";
import { CountdownProgress } from './CountdownProgress';
import { BoxEditById } from './BoxEdit';
import { useMediaQuery } from 'react-responsive'
import Countdown from "react-countdown-now";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Start din Homeboxx', 'Tast inn koden', 'Din Homeboxx er nesten klar'];
}

const TurnOnBox = ({ handleNext }) => {
    const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })

    return (
        <>
            {isTabletOrMobileDevice ?
                <img src={startBoxImage} alt={"Slå på homeboxxen din"} style={{height: 150}} /> :
                <img src={startBoxImage} alt={"Slå på homeboxxen din"} />
            }
            <Grid container justify="flex-end" spacing={3}>
                <Grid item>
                    <Typography variant="h6">Slå på boksen din</Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                        Klar
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

const PairingRequest = ({ setBoxId, handleNext }) => {
    const { apiBaseUrl, getTokenSilently } = useAuth0()
    const [attemptCount, setAttemptCount] = React.useState(1)
    const [loading, setLoading] = React.useState(true)
    const [pairingRequest, setParingRequest] = React.useState({ id: null, successful: false, boxId: null })

    React.useEffect(() => {
        const fn = async () => {
            const token = await getTokenSilently()
            const response = await fetch(`${apiBaseUrl}/api/boxes/pairing-requests`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            const responseData = await response.json()
            setParingRequest(responseData)
            setLoading(false)
        }

        fn()
    }, [attemptCount, apiBaseUrl, getTokenSilently, setLoading])

    React.useEffect(() => {
        if (pairingRequest.id === null) {
            return
        }

        const fn = async () => {
            const token = await getTokenSilently()
            const response = await fetch(`${apiBaseUrl}/api/boxes/pairing-requests/${pairingRequest.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            const responseData = await response.json()
            setParingRequest(responseData)
        }

        const timer = setInterval(fn, 1000);
        return () => {
            clearInterval(timer)
        }
    }, [pairingRequest.id, apiBaseUrl, getTokenSilently])

    React.useEffect(() => {
        if (!pairingRequest.successful) {
            return
        }

        setBoxId(pairingRequest.boxId)
        handleNext()
    }, [pairingRequest.successful, pairingRequest.boxId, setBoxId, handleNext])

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <>
            <Grid container alignItems="center" justify="center" spacing={1}>
                <Grid item>
                    <Typography>Tast inn koden: {pairingRequest.pairingCode}</Typography>
                </Grid>
                <Grid item>
                    <Countdown
                      // Note the key to force re-render.
                      key={attemptCount}
                      autoStart={true}
                      date={pairingRequest.expiresAt}
                      onComplete={() => setAttemptCount(a => a + 1)}
                      renderer={({total}) => (
                        <CountdownProgress total={60} remaining={total / 1000}/>
                      )}
                        />
                </Grid>
            </Grid>
            {/* <Grid container justify="center">
                <Button onClick={() => simulatePairing(pairingRequest.pairingCode)}>Simulate</Button>
            </Grid> */}
        </>
    );
}

const PairingStepContent = ({ activeStep, handleNext, boxId, setBoxId }) => {

    let stepContent;
    switch (activeStep) {
        case 0:
            stepContent = <TurnOnBox handleNext={handleNext} />;
            break
        case 1:
            stepContent = <PairingRequest setBoxId={setBoxId} handleNext={handleNext} />;
            break
        case 2:
            stepContent = <BoxEditById boxId={boxId} />;
            break
        default:
            stepContent = 'Unknown stepIndex';
    }

    return (
        <div>
            {stepContent}
        </div>
    )
}

export const BoxPairing = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [boxId, setBoxId] = React.useState()
    const steps = getSteps();

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Grid container justify="center">
                <PairingStepContent
                    activeStep={activeStep}
                    handleNext={handleNext}
                    boxId={boxId}
                    setBoxId={setBoxId}
                />
            </Grid>
        </div>
    );
}
