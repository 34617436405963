import React from 'react';
import BoxForm from './BoxForm';
import { withRouter } from 'react-router-dom'
import useFetch from '../utils/api';
import { useAuth0 } from "../utils/react-auth0-wrapper";

export const BoxEdit = withRouter(({ match: { params } }) => {
    return (
        <>
            <BoxEditById
                boxId={params.boxId}

            />
        </>
    );
});

export const BoxEditById = withRouter(({ boxId, history }) => {
    const { apiBaseUrl, getTokenSilently } = useAuth0()
    const { data, loading } = useFetch(`/api/boxes/${boxId}`)

    const handleSubmit = async (box) => {
        console.log(`About to save ${JSON.stringify(box)}`)

        const token = await getTokenSilently()
        const response = await fetch(`${apiBaseUrl}/api/boxes/${boxId}`,
            {
                method: "PUT",
                body: JSON.stringify({
                    name: box.name,
                    ownerName: box.ownerName,
                    ownerPhoneNumber: box.ownerPhoneNumber,
                    ownerEmail: box.ownerEmail,

                    ownerAddress: box.ownerAddress,
                    ownerPostalCode: box.ownerPostalCode,
                    ownerCity: box.ownerCity,

                    pins: box.pins,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`

                }
            }
        )
        console.log(response)
        history.push("/")
    }

    function handleCancel(e) {
        e.preventDefault();

        history.goBack()
        console.log('you have canceled');
    }
    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <>
            <BoxForm
                box={data}
                submitText="Save"
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
            />
        </>
    );
})




export default BoxEdit;