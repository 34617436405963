import React from 'react';
import BoxAdminList from "../components/BoxAdminList";
import AdAdminList from "../components/AdAdminList";
const Admin = () => (
  <>
    <BoxAdminList />
    <br />
    <AdAdminList />
  </>
);

export default Admin;
