import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useAuth0 } from "./utils/react-auth0-wrapper";
import { makeStyles } from "@material-ui/core/styles";
import BoxList from "./components/BoxList";
import Admin from "./views/Admin";
import BoxEdit from "./components/BoxEdit";
import Paper from "@material-ui/core/Paper";
import { BoxPairing } from './components/BoxPairing';
import BoxDetail from "./views/BoxDetails";
import * as axios from "axios";
import AxiosProvider from "react-axios/lib/components/AxiosProvider";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
    float: theme.right,
  },
  content: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  progress: {
    margin: theme.spacing(2),
  },
  avatar: {
    margin: 10,
  },
}));

console.log(window.location);

const App = () => {

  const classes = useStyles();

  // See https://auth0.com/docs/quickstart/spa/react
  const {
    token, apiBaseUrl, // Used to create axios instance.
    loading,
    isAuthenticated,
    user,
    logout,
    loginWithRedirect
  } = useAuth0();

  // TODO: I am seeing a lot of rerender here.
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    const login = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname }
      });
    };
    login();
  }, [loading, isAuthenticated, loginWithRedirect]);

  // Create an authenticated axios instance for the provider.
  const axiosInstance = axios.create({
    baseURL: apiBaseUrl,
    timeout: 2000,
    headers: {'Authorization': `Bearer ${token}`}
  });

  if (loading || !user) {
    return (
      <Paper className={classes.root}>
        <Container className={classes.content} maxWidth="sm">
          <CircularProgress className={classes.progress} />
        </Container>
      </Paper>
    );
  }
  try {
    return (
      <AxiosProvider instance={axiosInstance}>
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <Avatar alt={user.name} src={user.picture} className={classes.avatar}/>
              <Typography variant="h6" className={classes.title}>
                {user.name}
              </Typography>
              <Button color="inherit" href={"/logout"}>Logout</Button>
            </Toolbar>
          </AppBar>
          <Container maxWidth="lg">
            <Paper className={classes.content}>
              <BrowserRouter>
                <Switch>
                  <Route
                    path="/login"
                    render={() => loginWithRedirect()}
                  />
                  <Route
                    path="/logout"
                    render={() => logout()}
                  />
                  <Route exact path="/pair" component={BoxPairing}/>
	                <Route exact path="/admin" component={Admin}/>
                  <Route path="/boxes/:boxId/details" component={BoxDetail}/>
                  <Route path="/boxes/:boxId" component={BoxEdit}/>
                  <Route exact path="/">
                    <BoxList/>
                  </Route>
                </Switch>
              </BrowserRouter>
            </Paper>
          </Container>
        </div>
      </AxiosProvider>
    );
  } catch (error) {
    this.setState({ error });
    return (
      <div>
        {error}
      </div>
    )
  }


};

export default App;
