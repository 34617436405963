import { useState, useEffect } from 'react'
import { useAuth0 } from "./react-auth0-wrapper";

export const useFetch = url => {
  const { apiBaseUrl, getTokenSilently } = useAuth0();
  const [state, setState] = useState({ data: null, loading: true })

  useEffect(() => {
    const fn = async () => {
      const token = await getTokenSilently();
      const response = await fetch(`${apiBaseUrl}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        cache: "no-store"
      });

      const responseData = await response.json();
      setState({ data: responseData, loading: false })
    }

    fn()
  }, [url, apiBaseUrl, getTokenSilently])

  return state
};

export default useFetch;
