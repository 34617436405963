import React, { useState } from "react";
import { Grid, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink, Redirect } from 'react-router-dom';
import useFetch from "../utils/api";
import OTP from "./OTP";
import { useAuth0 } from "../utils/react-auth0-wrapper";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 200,
  },
  inputs: {
    margin: theme.spacing(3, 2),
  },
  actions: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const BoxList = ({ history }) => {
  const classes = useStyles();
  const [cacheBreak, setCacheBreak] = useState(0)
  const { data, loading } = useFetch(`/api/boxes?cache-break=${cacheBreak}`)
  const { apiBaseUrl, getTokenSilently } = useAuth0()
  const [showUnPairModal, setUnPairModal] = useState(false)

  const clickUnPair = () => {
    setUnPairModal(true);
  };

  const cancelUnPair = () => {
    setUnPairModal(false);
  };

  const unPairBox = async (boxId) => {
    try {
      const token = await getTokenSilently();
      // TODO: Handle failure.
      await fetch(`${apiBaseUrl}/api/boxes/${boxId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUnPairModal(false);
    } catch (e) {
      console.error("Failed to un-pair", e);
    }

    // TODO: Mauricio, what does this do?? Is it to handle refresh?
    setCacheBreak(c => c + 1)
  };

  if (loading) {
    return (<div>Loading...</div>);
  }
  if (data.length === 0) {
    return (<Redirect to={"/pair"} />)
  }

  return (
    <>
      <div className={classes.tableWrapper}>
        <Grid container spacing={1}>
          {data.map(box => (
            <Grid key={box.id} container item xs={12} spacing={3} alignItems="center">
              <Grid item xs={4}>
                <Typography className={classes.heading}>{box.name ? box.name : "Homeboxx"}</Typography>
              </Grid>
              <Grid item xs={4}>
                <OTP boxId={box.id} />
              </Grid>
              <Grid item alignContent="flex-end" xs={4}>
                <Link component={RouterLink} to={`/boxes/${box.id}`}>
                  <Button>Edit</Button>
                </Link>
                <Button onClick={() => clickUnPair()}>Remove</Button>
              </Grid>
              <Dialog
                open={showUnPairModal}
                onClose={() => cancelUnPair()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure you want to remove this box?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => cancelUnPair()} color="primary">
                    No
                  </Button>
                  <Button onClick={() => unPairBox(box.id)} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Link component={RouterLink} to={"/pair"}>
          <Button variant="contained" color="secondary" className={classes.button}>Add box</Button>
        </Link>
      </Grid>
    </>
  );
};

export default BoxList;