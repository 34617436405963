import React from "react";
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import useFetch from "../utils/api";
import { CountdownProgress } from "./CountdownProgress";
import Countdown from 'react-countdown-now';

const OTP = ({boxId}) => {
  const [refresh, triggerRefresh] = React.useState(0);
  const {data, loading} = useFetch(`/api/boxes/${boxId}/totp?refresh=${refresh}`);

  if (loading) {
    return (<div>Loading...</div>);
  }

  const dateFrom = Date.parse(data.validFrom);
  const dateTo = Date.parse(data.validTo);

  return (
    <Grid container alignItems="center" justify="center" spacing={1}>
      <Grid item>
        <Typography>OTP: {data.otp}</Typography>
      </Grid>
      <Grid item>
        {/*Split the count down logic from the presentation.*/}
        <Countdown
          // Note the key to force re-render.
          key={refresh}
          autoStart={true}
          date={dateTo}
          onComplete={() => triggerRefresh(a => a + 1)}
          renderer={({total}) => (
            <CountdownProgress total={(dateTo - dateFrom) / 1000} remaining={total / 1000}/>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default OTP;