import React from 'react';
import MaterialTable from 'material-table';
import useFetch from "../utils/api";
import { useAuth0 } from "../utils/react-auth0-wrapper";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export default function BoxAdminList() {
    const { apiBaseUrl, getTokenSilently } = useAuth0()
    const [cacheBreak, setCacheBreak] = React.useState(0)
    const [boxIdToUnPair, setBoxIdToUnPair] = React.useState()
    const [showUnPairDialog, setShowUnPairDialog] = React.useState(false)

    const { data, loading } = useFetch(`/admin-api/boxes?cache-break=${cacheBreak}`)

    const cancelUnPair = () => setShowUnPairDialog(false)
    const unPairBox = (boxId) => {
        getTokenSilently()
            .then(token =>
                fetch(`${apiBaseUrl}/admin-api/boxes/${boxId}/un-pair`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`

                        }
                    }
                )
            )
            .then(response => {
                cancelUnPair();
                setCacheBreak(cacheBreak + 1);
            })

    }

    if (loading) {
        return (<div>Loading...</div>);
    }

    return (
        <>
            <MaterialTable
                title="Box list"
                columns={[
                    { title: 'IMEI', field: 'id', editable: 'never' },
                    { title: 'Technical Name', field: 'technicalName' },
                    {
                        title: 'Box Type',
                        field: 'boxType',
                        editComponent: (props) => (
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.rowData.boxType || ''}
                                onChange={(event) => props.onChange(event.target.value)}
                            >
                                <MenuItem value="BASIC">BASIC</MenuItem>
                                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                            </Select>
                        )
                    },
                    { title: 'Note', field: 'technicalNote' },
                    { title: 'User\'s Box Name', field: 'name', editable: 'never' },
                    { title: 'Master Code', field: 'masterPinCode', editable: 'never' },
                    { title: 'Operator Code', field: 'operatorPinCode', editable: 'never' },
                    { title: 'Owner', field: 'ownerName', editable: 'never' },
                    { title: 'Phone', field: 'ownerPhoneNumber', editable: 'never' },
                    { title: 'Email', field: 'ownerEmail', editable: 'never' },
                    { title: 'Last Seen', field: 'lastSeenAt', editable: 'never' },
                    { title: 'Firmware', field: 'lastReportedFirmware', editable: 'never' },
                ]}
                data={data}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        getTokenSilently()
                            .then(token =>
                                fetch(`${apiBaseUrl}/admin-api/boxes/${newData.id}`,
                                    {
                                        method: "PUT",
                                        body: JSON.stringify({
                                            technicalName: newData.technicalName,
                                            technicalNote: newData.technicalNote,
                                            boxType: newData.boxType,
                                        }),
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${token}`

                                        }
                                    }
                                )
                            )
                            .then(response => setCacheBreak(cacheBreak + 1)),
                }}
                actions={[
                    {
                        icon: 'link_off',
                        tooltip: 'Un Pair',
                        onClick: (event, rowData) => {
                            setBoxIdToUnPair(rowData.id);
                            setShowUnPairDialog(true);
                        }
                    }
                ]}
                options={{
                    exportButton: true
                }}
            />
            <Dialog
                open={showUnPairDialog}
                onClose={cancelUnPair}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to un pair this box?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelUnPair} color="primary">
                        No
                  </Button>
                    <Button onClick={() => unPairBox(boxIdToUnPair)} color="primary" autoFocus>
                        Yes
                  </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
