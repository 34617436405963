import React from 'react';
import MaterialTable from 'material-table';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import useFetch from "../utils/api";
import { useAuth0 } from "../utils/react-auth0-wrapper";

const AdAdminList = () => {
  const [cacheBreak, setCacheBreak] = React.useState(0);
  const { apiBaseUrl, getTokenSilently } = useAuth0();
  const { data, loading } = useFetch(`/admin-api/ads?cache-break=${cacheBreak}`);
  const [file, setFile] = React.useState(null);

  const onDelete = async (id) => {
    const token = await getTokenSilently();
    if (token) {
      await fetch(`${apiBaseUrl}/admin-api/ads/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });
      setCacheBreak(cacheBreak + 1);
    }
  }

  const onUpdate = async (data) => {
    const token = await getTokenSilently();
    if (token) {
      const formData = new FormData();
      if (file?.name) {
        formData.append('file', file);
      }
      formData.append('url', data.url);
      formData.append('isPublished', data.isPublished);
      await fetch(`${apiBaseUrl}/admin-api/ads/${data.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      setFile(null);
      setCacheBreak(cacheBreak + 1);
    }
  }

  const onCreate = async (data) => {
    const token = await getTokenSilently();
    if (token) {
      const formData = new FormData();
      if (file?.name) {
        formData.append('file', file);
      }
      formData.append('url', data.url);
      await fetch(`${apiBaseUrl}/admin-api/ads`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      setFile(null);
      setCacheBreak(cacheBreak + 1);
    }
  }

  const onToggle = async (data, isChecked) => {
    const token = await getTokenSilently();
    if (token) {
      const formData = new FormData();
      formData.append('isPublished', isChecked);
      formData.append('url', data.url);
      await fetch(`${apiBaseUrl}/admin-api/ads/${data.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      setFile(null);
      setCacheBreak(cacheBreak + 1);
    }  }

  if (loading) {
    return (<div>Loading...</div>);
  }

  return (
    <MaterialTable
      title="Advertisements"
      data={data}
      editable={{
        onRowAdd: newData => onCreate(newData),
        onRowDelete: oldData => onDelete(oldData.id),
        onRowUpdate: rowData => onUpdate(rowData),
        onRowUpdateCancelled: () => setFile(null),
        onRowAddCancelled: () => setFile(null),
      }}
      columns={[
        { title: 'Image',
          field: 'imageUrl',
          render: rowData => <img src={rowData.imageUrl} style={{ width: 100 }} alt="ad" />,
          editComponent: () => (
            <>
              <Button color="primary" onClick={() => document.getElementById('file').click()}>
                {file ? file?.name : 'Upload an image'}
              </Button>
              <input
                id="file"
                type="file"
                accept="image/*"
                onChange={(event) => {
                  setFile(event.target.files[0]);
                }}
                style={{ display:'none' }}
              />
            </>
          )
        },
        { title: 'URL', field: 'url' },
        {
          title: 'Published?',
          field: 'isPublished',
          editComponent: () => (
            <Checkbox
              checked
              disabled
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          ),
          render: rowData => (
            <Checkbox
              checked={rowData.isPublished}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={({ target: { checked } }) => onToggle(rowData, checked)}
            />
          )
        },
      ]}
    />
  );
}

export default AdAdminList;
