import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import { green, yellow, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  progressOk: {
    color: green[500],
    position: 'absolute',
    top: -1,
    left: -1,
    zIndex: 1,
  },
  progressYellow: {
    color: yellow[800],
    position: 'absolute',
    top: -1,
    left: -1,
    zIndex: 1,
  },
  progressRed: {
    color: red[500],
    position: 'absolute',
    top: -1,
    left: -1,
    zIndex: 1,
  },
}));

export const CountdownProgress = ({ total, remaining, warn = 50, err = 10}) => {
  const classes = useStyles();
  const progress = remaining / total * 100;
  const progressClass = progress >= 50 ? classes.progressOk : progress >= 25 ? classes.progressYellow : classes.progressRed;
  return (
    <div className={classes.wrapper}>
      <Fab
        aria-label="save"
        color="inherit"
        size="small"
      >
        {remaining}
      </Fab>
      <CircularProgress size={42} className={progressClass} variant="static" value={ progress } />
    </div>
  )
}
