import React from 'react';
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Moment from "react-moment";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import Button from "@material-ui/core/Button";
import {Get} from 'react-axios'
import * as moment from "moment";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {withRouter} from "react-router-dom";
import Typography from "@material-ui/core/Typography";

// The temperature graph.
const TemperatureLine = ({events}) => {

  const timeFormatter = (tick) => {
    return moment(tick).calendar();
  };

  return (
    <ResponsiveContainer width='100%' height={200}>
      <LineChart data={events}
                 margin={{
                   top: 16,
                   right: 16,
                   bottom: 0,
                   left: 24,
                 }}>
        <CartesianGrid strokeDasharray="3 3"/>
        <Line type="monotone"
              dataKey="temp"
              stroke="#8884d8"
              dot={false}
              activeDot={true}
              isAnimationActive={false}/>
        <YAxis domain={['dataMin - 10', 'dataMax + 10']}/>
        <XAxis
          dataKey="time"
          tickFormatter={timeFormatter}
          interval="preserveEnd"
        />
        <Tooltip/>
        <Legend/>
      </LineChart>
    </ResponsiveContainer>
  );
};

// Glue the chart with the API.
const BoxTemperatureChart = ({boxId}) => (
  <Get url={`/api/boxes/${boxId}/events`} params={{last: 100, type: 'TEMPERATURE'}}>
    {(error, response, isLoading, makeRequest) => {
      if (error) {
        return (<div>Something bad happened: {error.message}
          <button onClick={() => makeRequest({params: {reload: true}})}>Retry</button>
        </div>)
      } else if (isLoading) {
        return (<div>Loading...</div>)
      } else if (response !== null) {

        const {
          first, last, data
        } = response.data;
        const events = data.map(({elem: {time, payload: {celsius}}}) => ({time: time, temp: celsius}));

        return (
          <div style={{height: "200px"}}>
            <TemperatureLine
              events={events || []}
              from={moment.utc(first)}
              to={moment.utc(last)}
            />
            <button onClick={() => makeRequest({params: {last: 100, before: first, type: 'TEMPERATURE'}})}>prev</button>
            <button onClick={() => makeRequest({params: {first: 100, after: last, type: 'TEMPERATURE'}})}>next</button>
          </div>)
      }
      return (<div>Default message before request is made.</div>)
    }}
  </Get>
);

const BoxErrorCard = ({error, callback}) => (
  <Card>
    <CardContent>
      Could not load the box: {error.message}
    </CardContent>
    <CardActions>
      <Button
        onClick={() => callback({params: {reload: true}})}
        size="small"
        color="primary">
        retry
      </Button>
    </CardActions>
  </Card>
);

const BoxLoadingCard = () => (
  <Card>
    <CardContent style={{height:'200px', display:'block'}}>
      Loading...
    </CardContent>
  </Card>
);

const BoxCard = ({box}) => (
  <Card key={box.id}>
    <CardHeader
      title={(box.name)}
      subheader={(<>last seen <Moment fromNow>{box.lastSeen}</Moment></>)}
      action={
        <IconButton aria-label="details">
          <InfoIcon/>
        </IconButton>
      }
    />
    <CardContent>
      <BoxTemperatureChart boxId={box.id}/>
    </CardContent>
    <CardContent>
      <Typography variant="caption" display="block" gutterBottom>
        {box.lastReportedFirmare !== box.firmware
          ? <span>firmware version: {box.firmware} </span>
          : <span>firmware version: {box.lastReportedFirmare}, update to {box.firmware} planned </span>}
      </Typography>
    </CardContent>
    <CardActions>
      <Button
        size="small"
        color="primary">
        Generate
        pin
        code
      </Button>
      <Button size="small" color="secondary">
        History
      </Button>
    </CardActions>
  </Card>
);

const BoxDetail = withRouter(({match: {params: {boxId}}}) => (
  <Get url={`/api/boxes/${boxId}`} params={{last: 100, type: 'TEMPERATURE'}}>
    {(error, response, isLoading, makeRequest) => {
      if (error) {
        return <BoxErrorCard error={error} callback={makeRequest}/>
      } else if (isLoading) {
        return <BoxLoadingCard/>
      } else if (response !== null) {
        return <BoxCard box={response.data}/>
      }
      return <BoxLoadingCard/>
    }}
  </Get>
));

export default BoxDetail;

